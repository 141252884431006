import React from 'react';
import Helmet from '../project/Helmet';
import DepartmentAdd from './departments/DepartmentAdd';
import DepartmentsList from './departments/DepartmentsList';
import {t} from '@lingui/macro';

/**
 * @fero
 */

class DepartmentsPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Oddelenia`}
            />
            <div className="full-size-height d-flex flex-column">
                <div className="pt-2 add-max-width flex-item-static">
                    <DepartmentAdd/>
                </div>
                <div className="m-3 overflow-auto flex-item-dynamic-1">
                    <DepartmentsList/>
                </div>
            </div>
        </React.Fragment>;
    }

}

export default DepartmentsPage;