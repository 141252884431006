import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import DepartmentForm from './DepartmentForm';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import {Department} from '../../../constants/propTypesDefinitions';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class DepartmentEdit extends React.PureComponent {
    static propTypes = {
        department: Department.isRequired,
        reload: PropTypes.func.isRequired,
    }.isRequired;

    render() {
        const {department, reload} = this.props;
        
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Úprava oddelenia</Trans>}>
                    <Button className="mx-1" icon="edit" size="small"/>
                </Tooltip>
            }
            values={{isEdit: true, department: department}}
            onFinishSuccessful={reload}
            title={<Trans>Úprava oddelenia</Trans>}
            Form={DepartmentForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa upraviť oddelenie`)}
        />;
    }

}

export default DepartmentEdit;